import React from "react";

const Education = () => (<div className="row text-center">
    <div className="col-12 left-header">
        Education
    </div>
    <i className="col-12  fa fa-graduation-cap fa-3x"/>
    <ul className=" col-12 left-sub-text col-md-12 ">
        <li>Northeastern University</li>
        <li>Computer Science</li>
        <li>Class of 2018</li>
    </ul>
</div>);


export default Education;
